@media (min-width: 992px) {
  .somespacelast {
    padding-top: 50px;
  }
  .wyslij_small {
    position: absolute;
    top: 30px;
    right: -30px;
  }
  .greyblack_light {
    padding-top: 60px;
  }
  .alignmidle {
    padding-left: 5px;
  }
}
@media (min-width: 768px) {
  .alignmidle {
    height: 200px;
    vertical-align: middle;
    align-items: center;
    display: flex;
    padding-left: 30px;
  }
  .navbar-right {
    margin-right: 45px;
  }
  .modal-dialog {
    width: 900px;
    margin: 30px auto;
  }
}

@media (max-width: 767px) {
  .preview {
    height: auto !important;
    min-height: auto !important;
    background: none !important;
  }
  .modal-dialog .list_positioner {
    /* zoom: 0.46; */
    position: static !important;
    padding: 10px;
    left: auto;
    top: auto;
  }
  .modal-dialog .list_positioner p {
    width: auto !important;
  }
  .outerspace {
    margin: 0 -30px;
  }
  .center-sm {
    text-align: center;
  }
  .jaknapisaclist img {
    margin-top: 20px;
    margin-bottom: 19px;
  }
  .subpage .tall {
    width: 90%;
  }
  .steps-container h2 {
    margin-bottom: 7px;
    margin-top: 0;
  }

  .faq p {
    background: none;
    padding-left: 0;
  }
  .faq p span {
    padding-left: 20px;
  }
  .dotation-container {
    padding: 60px 22px;
  }
  .dotation-container .container {
    padding: 0;
  }
  .dotation-container .tall {
    width: 100%;
  }
  .dotation-container .dot-info {
    font-size: 15px;
  }
  .dotation-container .dot-chooser {
    font-size: 18px;
    font-weight: bold;
    padding: 5px 18px;
  }
  .dotation-container .dot-chooser.dot-chooser-innakwota {
    padding: 13px;
  }
  .steps-container .step {
    padding: 10px 0;
    font-size: 16px;
  }
  .steps-container .tall {
    width: 60%;
  }
  .header-container .teaser br {
    display: none;
  }
  .steps-container .step img {
    position: relative;
    top: 11px;
    right: 5px;
  }
}

@media (min-width: 460px) and (max-width: 767px) {
  .dotation-container .dot-chooser {
    display: block;
    padding: 18px;
  }
  .dotation-container .dot-chooser.dot-chooser-innakwota {
    text-align: center;
  }
  .dotation-container .dot-chooser.dot-chooser-innakwota .inna_input {
    width: 100%;
  }
}

@media (min-width: 1200px) {
  .formgen .container {
    width: 690px;
    padding: 50px 0;
  }
}
@media (min-width: 1366px) {
  .letter-container {
    width: 1366px;
  }
}
@media (max-width: 1365px) {
  .letter-container {
    width: 100%;
  }
}
@media (max-width: 992px) {
  .personal-data-container .tall {
    width: 98%;
  }

  .subpage ul {
    margin-top: 30px;
  }
  .list_positioner p {
    width: 100%;
    font-size: 14px;
  }

  .footer-container .row {
    padding: 0;
  }
  .footer-container .row .col-md-6 {
    text-align: left !important;
    padding-bottom: 20px;
  }
  .links-container .infos {
    padding: 10px 0px;
  }

  .links-container .infos p br {
    display: none;
  }
  .letter-container p br {
    display: none;
  }

  .letter-container .slide {
    display: none;
  }
  .letter-container .slide.first-child {
    display: block;
  }
  .process-container .row {
    margin-top: 30px;
  }
  .process-container {
    padding: 20px 0;
  }
  .tall {
    width: 80%;
    margin: 0 auto;
  }
  .container-fluid.header-small-container {
    height: 87px !important;
  }
  .container-fluid.header-container .teaser p img {
    margin-top: 5px;
    width: 70%;
  }
  .container-fluid.header-container .teaser {
    font-size: 26px;
    margin-left: 0;
    text-align: center;
  }
  .container-fluid.header-container .teaser p {
    font-size: 18px;
    margin-top: 21px;
  }
  .container-fluid.header-container {
    background: url(../images/fdds-list-od-mikolaja-1366x700.png) no-repeat left;
    height: 564px;
    color: #fff;
  }
  .navbar-default .navbar-brand {
    color: #777;
    position: absolute;
    top: 0;
    left: -22px;
  }
  .navbar-default .navbar-toggle .icon-bar {
    background-color: #fff;
  }

  .navbar-default .navbar-toggle,
  .navbar-default .navbar-toggle:focus,
  .navbar-default .navbar-toggle:hover {
    background-color: transparent;
    border: 0;
    position: absolute;
    top: 14px;
    right: 61px;
  }
  .navbar-default .navbar-toggle {
    border: 0;
  }

  .navbar-toggle .icon-bar {
    display: block;
    width: 35px;
    height: 7px;
    border-radius: 1px;
  }
  .navbar-default .navbar-collapse,
  .navbar-default .navbar-form {
    border: 1px solid #7692aa;
    width: 100%;
    position: absolute;
    top: 81px;
    right: 0;
    background: #385f80;
    z-index: 100000;
  }

  .navbar-headers .navbar-nav a {
    padding-bottom: 5px;
    font-size: 19px;
    color: #fff;
  }

  .navbar-headers .navbar-nav {
    margin-top: 0;
  }
  .navbar-headers .navbar-nav.mainnav {
    position: absolute;
    top: 80px;
    right: 0px;
    padding: 10px;
    background: #385f80;
    width: 100vw;
    max-width: 350px;
    z-index: 10000;
  }
  .hiddenMobile {
    display: none;
  }
}

@media (max-width: 992px) {
  .navbar-header {
    float: none;
  }
  .navbar-left,
  .navbar-right {
    float: none !important;
  }
  .navbar-toggle {
    display: block;
  }
  .navbar-collapse {
    border-top: 1px solid transparent;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
  }
  .navbar-fixed-top {
    top: 0;
    border-width: 0 0 1px;
  }
  .navbar-collapse.collapse {
    display: none !important;
  }
  .navbar-nav {
    float: none !important;
    margin-top: 7.5px;
  }
  .navbar-nav > li {
    float: none;
  }
  .navbar-nav > li > a {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .collapse.in {
    display: block !important;
  }

  .container-fluid.counter-container {
    height: 600px;
  }

  .container-fluid.counter-container .full-counter-container {
    font-size: 40px;
    color: #000;
    font-weight: bold;
    text-align: center;
    line-height: 67px;
    /* top: 245px;
    left: 523px;
    position: absolute; */
    margin-top: 250px;
    margin-left: 10px;
  }
  .container-fluid.counter-container .full-counter-container .counter-counter {
    color: #e8404b;
  }
  .container-fluid.counter-container
    .full-counter-container
    .counter-counter
    span {
    font-size: 170%;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .outerspace {
    margin: 0 -30px;
  }
  .subpage ul.morepad-sm {
    padding: 40px;
  }
  .tall.tall100 {
    width: 99%;
  }
  .dotation-container .tall {
    width: 100%;
  }
  .letter-container .slide {
    display: none;
  }
  .letter-container .slide.first-child {
    display: block;
  }
  .letter-container .slide.sm-child {
    display: block;
  }
  .letter-container.dar-infor p br {
    display: block;
  }
  .steps-container .step .col-sm-6 {
    padding: 0;
  }
  .steps-container .tall {
    width: 92%;
  }
  .steps-container {
    font-size: 14px;
  }
  div#letter_slider {
    margin-left: 40px;
  }

  /*
.navbar-default .navbar-toggle, .navbar-default .navbar-toggle:focus, .navbar-default .navbar-toggle:hover {
    background-color: transparent;
    border:0;
    position: absolute;
    top: 14px;
    right: -31px;
}
*/
  .container-fluid.header-container .teaser {
    font-size: 26px;
    margin-left: 20px;
    text-align: left;
    padding-right: 287px;
  }
  .container-fluid.header-container {
    background: url(../images/fdds-list-od-mikolaja-1366x700.png) no-repeat -121px 0px;
    height: 564px;
    color: #fff;
  }
  .navbar > .container .navbar-brand,
  .navbar > .container-fluid .navbar-brand {
    margin-left: 0;
  }
}
