@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,700,700i&subset=latin-ext");

body {
  font-family: "Open Sans", sans-serif;
}

a {
  cursor: pointer;
}

.container-fluid.header-container {
  /*background: url(../images/2020/header.png) no-repeat top center;*/
  background: url(../images/fdds-list-od-mikolaja-1366x700.png) no-repeat top center;

  height: 700px;
  color: #fff;
}

.container-fluid.header-small-container {
  background: url(../images/2020/header_small.png) no-repeat top center;
  height: 100px;
  color: #fff;
}

.container-fluid.counter-container {
  background: url(../images/2020/worek.png) no-repeat top center;
  height: 600px;
}

.container-fluid .container {
  position: relative;
}

.container-fluid.counter-container .full-counter-container {
  font-size: 50px;
  color: #000;
  font-weight: bold;
  text-align: center;
  line-height: 67px;

  margin-top: 250px;
  margin-left: 250px;
}

.container-fluid.counter-container .full-counter-container .counter-counter {
  color: #e8404b;
}

.container-fluid.counter-container
.full-counter-container
.counter-counter
span {
  font-size: 170%;
}

.teaser-counter {
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 27px;
  color: #e8404b;
  font-weight: bold;
  text-align: center;
  line-height: 45px;
}


.teaser-counter .counter-counter {
  color: #e8404b;
}

.teaser-counter .counter-counter span {
  font-size: 170%;
}

@media (min-width: 980px) {
  .teaser-counter {
    bottom: -140px;
    right: 117px;
  }
}

@media (min-width: 1200px) {
  .teaser-counter {
    bottom: -140px;
    right: 220px;
  }
}

@media (max-width: 979px) {
  .teaser-counter {
    display: none;
  }
}

@media (min-width: 980px) {
  .counter-container {
    display: none;
  }
}

.container-fluid.header-container .teaser {
  font-size: 36px;
  font-weight: bold;
  margin-top: 102px;
  margin-left: -160px;
  text-shadow: 1px 1px 0px rgba(0, 0, 0, 1);
}

.container-fluid.header-container .teaser span {
  color: #e8404b;
}

.container-fluid.header-container .teaser p {
  font-size: 24px;
  font-weight: bold;
  margin-top: 20px;
}

.container-fluid.header-container .teaser p:last-child {
  margin-top: 17px;
}

.container-fluid.header-container .teaser p img {
  margin-top: 5px;
}

.navbar-headers .navbar-nav {
  margin-top: 18px;
}

.navbar-brand {
  margin-top: 5px;
}

.navbar-headers .navbar-nav a {
  padding-bottom: 5px;
  font-size: 15px;
  color: #fff;
  border-bottom: 1px solid transparent;
}

.navbar-headers .navbar-nav a:hover {
  background: none;
  border-bottom: 1px solid #fff;
}

.container-fluid.header-container a {
  color: #fff;
}

.process-container {
  font-size: 18px;
  font-weight: 300;
}

.process-container h2 {
  font-size: 26px;
  font-weight: bold;
  color: #000;
}

.process-container .info_text {
  position: relative;
  top: -20px;
}

/* .process-container .container {
} */

.steps-container {
  padding: 40px 0;
  font-weight: 300;
  font-size: 18px;
}

.steps-container h2 {
  font-size: 26px;
  padding-top: 0;
  font-weight: bold;
  color: #000;
  margin-bottom: 30px;
}

/*
.steps-container .step img {
} */

.steps-container .step div {
  padding-top: 5px;
}

.letter-container {
  background: #102941;
  color: #fff;
  font-size: 18px;
  font-weight: 300;
  /* background: url(img_new/blue_back.png) repeat-y top center; */

  padding: 40px 0;
  padding-bottom: 60px;
  min-height: 520px;
}

.letter-container h2 {
  font-size: 26px;
  padding-top: 0;
  font-weight: bold;
  color: #fff;
}

.letter-container p {
  margin-bottom: 40px;
}

.letter-container.chooser {
  min-height: 0;
  background: url(../images/light_blue_back.png) repeat-y top center;
  padding: 30px 0;
}

.letter-container.chooser #letter_slider .slide img.graf {
  cursor: pointer;
  border: 1px solid #c7d1de;
  width: 95%;
}

/*#letter_slider .slide img:hover  { width: 100%; }*/

/*.letter-container.chooser  #letter_slider .slide img.graf:hover { width: 100%; }  */

.letter-container.chooser #letter_slider .slide img.selected.graf {
  width: 100%;
}

.letter-container.chooser #letter_slider .slide img.graf :hover {
  border: 1px solid #fff;
}

.letter-container.chooser #letter_slider .slide img.check {
  position: absolute;
  top: -11px;
  left: 80px;
  display: none;
}

.formgen {
  margin-bottom: 20px;
}

.letter-container.chooser #letter_slider {
  height: 270px;
}

.letter-container.chooser h3,
.formgen h3 {
  color: #000;
  font-size: 18px;
  font-weight: bold;
  padding: 0;
  margin-bottom: 30px;
}

#letter_slider .arr {
  padding-top: 62px;
  cursor: pointer;
}

#letter_slider .arr.blinder {
  cursor: default;
}

#letter_slider .blinder {
  opacity: 0.5;
}

.links-container .infos {
  padding: 50px 70px;
}

.links-container h2 {
  font-weight: bold;
  border-bottom: #c6d0dc 8px solid;
  font-size: 26px;
  padding-top: 0;
  color: #000;
  display: inline-block;
}

.links-container p {
  font-weight: 300;
  font-size: 18px;
  color: #000;
}

#letter_slider small {
  font-size: 85%;
  display: block;
}

.letter-container.chooser #letter_slider small {
  color: #000;
}

.link {
  border-bottom: 1px solid #e83f4b;
  color: #e83f4b;
  cursor: pointer;
  text-decoration: none;
}

.link:hover {
  color: #e83f4b;
  text-decoration: none;
}

.footer-container {
  background: url(../images/footer_bg.png) repeat-y top center;
  padding: 40px 0;
  padding-bottom: 60px;
  color: #fff;
  font-size: 16px;
  font-weight: 300;
}

.footer-container .row {
  padding: 0 70px;
}

.footer-container a {
  color: #fff;
}

.tall {
  width: 80%;
  margin: 0 auto;
}

.ui-selectmenu {
  height: auto;
  font-size: 12px;
  margin: 0;
}

.ui-selectmenu-status {
  line-height: 1.9em;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
}

.preview_infor {
  font-weight: bold;
  font-size: 22px;
  margin-top: 20px;
  margin-bottom: 40px;
}

.big_red {
  font-weight: normal;
  font-size: 18px;
  text-align: center;
  text-transform: uppercase;
  background: #e73f4c;
  min-width: 135px;
  padding: 20px 10px;
  color: #fff;
  border: 0;
}

.preview_full {
  position: relative;
  min-height: 1363px;
  font-size: 16px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
}

.preview_full .list_positioner p {
  width: 600px;
  font-size: 16px;
}

.modal-content .preview.l1_2017 p {
  width: 489px;
  font-size: 12px;
}

.modal-content .preview.l4 p {
  width: 429px;
}

.preview_full .preview {
  background-size: 1000px !important;
  min-height: 1421px;
}

.preview {
  min-height: 1300px;
  background-repeat: no-repeat !important;
  /* line-height: 18px; */
}

.preview br {
  display: block;
  margin: 16px 0;
  content: " ";
  line-height: 16px;
}

.preview p {
  margin-bottom: 6px;
}

.preview_full .preview p {
  margin-bottom: 12px;
}

.list_positioner {
  position: absolute;
  top: 20px;
}

/* margin-bottom: 49px; */

.preview.l1_2017 .list_positioner {
  position: absolute;
  top: 341px;
  left: 186px;
}

.preview.l3_2017 .list_positioner {
  position: absolute;
  top: 274px;
  left: 207px;
}

.preview.l2_2017 .list_positioner {
  position: absolute;
  top: 291px;
  left: 224px;
}

.preview.l4_2017 .list_positioner {
  position: absolute;
  top: 278px;
  left: 237px;
}

.preview.kartka_2018_1 .list_positioner {
  position: absolute;
  top: 147px;
  left: 231px;
}

.preview.kartka_2018_2 .list_positioner {
  position: absolute;
  top: 267px;
  left: 247px;
}

.modal-dialog .preview.kartka_2018_2 .list_positioner p {
  width: 408px;
}

.preview.kartka_2018_3 .list_positioner {
  position: absolute;
  top: 247px;
  /* font-size: 9px; */
  left: 217px;
}

.preview.kartka_2018_4 .list_positioner {
  position: absolute;
  top: 341px;
  left: 186px;
}

.list_positioner br {
  display: block;
  margin: 13px 0;
  content: "";
  line-height: 5px;
}

.makehightlight {
  font-weight: bold;
}

.modal-dialog .preview.kartka_2018_3 .list_positioner p {
  font-size: 12px;
  width: 465px;
}

.modal-dialog .preview.kartka_2018_4 .list_positioner p {
  font-size: 14px;
  width: 500px;
}

.modal-content .preview.l4_2017 .list_positioner p {
  width: 362px;
}

/* 1_letter_big */
.modal-dialog .preview.\31_letter_big .list_positioner {
  position: absolute;
  top: 340px;
  left: 250px;
}

.modal-dialog .preview.\31_letter_big .list_positioner p {
  width: 400px;
}

/* 2_letter_big */
.modal-dialog .preview.\32_letter_big .list_positioner {
  position: absolute;
  top: 340px;
  left: 187px;
}

.modal-dialog .preview.\32_letter_big .list_positioner p {
  width: 475px;
}

/* 3_letter_big */
.modal-dialog .preview.\33_letter_big .list_positioner {
  position: absolute;
  top: 350px;
  left: 247px;
}

.modal-dialog .preview.\33_letter_big .list_positioner p {
  width: 430px;
}

/* 4_letter_big */
.modal-dialog .preview.\34_letter_big .list_positioner {
  position: absolute;
  top: 318px;
  left: 225px;
}

.modal-dialog .preview.\34_letter_big .list_positioner p {
  width: 440px;
}

.preview.l5 .list_positioner {
  position: absolute;
  top: 320px;
  left: 68px;
}

.preview.l5 .list_positioner p {
  width: 480px;
}

.preview.l4 .list_positioner p {
  font-size: 12px;
}

.personal-data-container {
  background: url(../images/gray_back.png) repeat-y top center;
  padding-bottom: 40px;
}

.personal-data-container .tall {
  width: 60%;
}

.copy-data {
  color: #000;
  display: inline-block;
  padding: 5px 10px;
  border: 1px solid #8fa0b3;
  background: #e3e8ef;
  font-size: 12px;
  cursor: pointer;
}

.copy-data:hover {
  background: #fff;
  text-decoration: none;
  color: #000;
}

.personal-data-container .copy-data {
  margin-bottom: 10px;
}

.letter-container.dar-infor {
  padding-bottom: 40px;
  min-height: 10px;
}

.letter-container.dar-infor p {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 20px;
}

.letter-container.dar-infor h2 {
  margin-bottom: 20px;
  margin-top: 0;
}

.dotation-container {
  background: url(../images/gray_back.png) repeat-y top center;
  padding: 60px 0;
}

.dotation-container .dot-chooser {
  cursor: pointer;
  font-size: 26px;
  background: #fff;
  border: 1px solid #324d69;
  padding: 20px 35px;
  color: #2e4d6a;
  display: inline-block;
  font-weight: 400;
}

.dotation-container .dot-chooser.check {
  background: #e8404b;
  color: #fff;
}

.inna_input {
  width: 120px;
  font-size: 13px;
}

.dotation-container .dot-info {
  font-size: 17px;
  color: #000;
  margin-top: 20px;
  font-weight: 200 p;
}

.small_preview {
  position: relative;
  margin-bottom: 20px;
}

.small_preview .preview {
  background: #e9edf1 !important;
  padding: 23px;
  border: 1px solid #2e4d6a;
}

.small_preview .list_positioner {
  position: static !important;
}

.small_preview .preview {
  min-height: 0;
}

.subpage h2 {
  font-size: 26px;
  font-weight: bold;
  margin-top: 50px;
  margin-bottom: 20px;
}

.subpage {
  padding-bottom: 30px;
  min-height: 100vh;

}

.subpage.nh {
  min-height: auto;
}

.subpage ul {
  font-size: 18px;
  font-weight: 200;
  list-style: none;
  padding: 0;
}

.subpage ul li {
  padding-left: 50px;
  background: url(../images/point.png) no-repeat top left;
  margin-bottom: 20px;
  min-height: 33px;
}

.subpage ul li:last-child {
  margin-top: 40px;
  background: none;
}

.subpage a {
  color: #e83f4b;
  font-weight: bold;
}

.subpage .toper {
  font-size: 18px;
  margin-bottom: 30px;
  font-weight: 200;
}

.subpage h3 {
  margin-top: 40px;
  margin-bottom: 30px;
  font-size: 18px;
  font-weight: bold;
}

.subpage .border-top {
  border-top: 1px solid #c7ccd0;
}

.small_preview .list_positioner p {
  width: 100%;
  font-size: 14px;
}

img#dziubek {
  position: relative;
  top: 5px;
  left: 20px;
}

.subpage {
  font-size: 120%;
}

.close {
  position: absolute;
  top: -7px;
  z-index: 1000;
  right: 2px;
  font-size: 34px;
  opacity: 1;
}

modal-content {
  padding: 5px;
}

.navbar-default {
  background: transparent;
  border: 0;
  box-shadow: none;
}

.navbar-default .navbar-nav > li > a,
.navbar-default .navbar-nav > li > a:hover {
  color: #fff;
}

.wyslij_small {
  position: fixed;
  top: 30px;
  right: 10px;
  cursor: pointer;
  background: #e8404b;
  padding: 5px 10px;
  border: 1px solid #000;
  z-index: 200000;
}

.light {
  font-weight: 200;
}

.litlepad {
  padding-top: 20px;
}

.litlepad2x {
  padding-top: 40px;
}

.litlepad3x {
  padding-top: 60px;
}

.greyblack {
  background: url(../images/gray_back.png) repeat-y top center;
}

.greyblack_light {
  background: url(../images/gray_back_light.png) repeat-y top center;
}

.blueblack {
  background: url(../images/blue_back.png) repeat-y top center;
  padding: 40px 0;
  padding-bottom: 60px;
  color: #fff;
}

.red {
  color: #e8404b;
}

.shadow {
  text-shadow: 1px 1px 0px rgba(0, 0, 0, 1);
}

.faq p {
  background: url(../images/close.png) no-repeat top left;
  padding-left: 20px;
  margin-bottom: 20px;
}

.faq strong {
  font-size: 18px;
  display: block;
  margin-bottom: 15px;
  cursor: pointer;
}

.faq span {
  margin-bottom: 15px;
  display: block;
}

.thankyou {
  background: url(../images/dziekuje.png) no-repeat bottom center;
  min-height: 574px;
}

button.navbar-toggle.collapsed.nored {
  right: -30px;
}

.ui-selectmenu {
  width: 100% !important;
}

.has-error .form-control {
  border-color: #f51612;
}

.zipper_searcher {
  margin-top: 3px;
}

.kod_hinter {
  display: inline-block;
}

.kod_hinter .zip_code {
  background: #fff;
  display: inline-block;
  padding: 0px 5px;
  border-radius: 3px;
  margin-left: 4px;
  cursor: pointer;
}

#mikolaj_ps-menu li:nth-child(2) a {
  color: red !important;
}

#mikolaj_umiejetnosc-menu li:last-child a {
  color: red !important;
}

#mikolaj_zabawa-menu li:last-child a {
  color: red !important;
}

#mikolaj_prezent-menu li:last-child a {
  color: red !important;
}

#mikolaj_prezent_without_red-menu li:last-child a {
  color: #000;
}

.navbar-default .navbar-nav > li > a:focus,
.navbar-default .navbar-nav > li > a:hover {
  color: #e3e8ef;
}

.mt-1 {
  margin-top: 5px;
}

.mt-2 {
  margin-top: 10px;
}

.mt-4 {
  margin-top: 20px;
}

.mb-4 {
  margin-bottom: 20px;
}

.error {
  color: red;
}

.error ::-webkit-input-placeholder {
  /* Edge */
  color: red;
}

.error :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: red;
}

.error ::placeholder {
  color: red;
}

.letter-container.chooser .error {
  color: red;
}

.marginH3button {
  margin-bottom: 49px;
}

.subpage ul.morepad-sm {
  padding: 40px;
}
